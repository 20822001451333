import React from "react";
import { Bounce, Fade, Zoom } from "react-reveal";
import { useTranslation } from 'react-i18next';

import { SectionTitle } from './CommonsComponents'

export default function Download(props) {
  const { ReactGA } = props;
  const { t } = useTranslation();

  const getCurrentAppVersion = () => {
    return "18.0"; // Supongo que esto es dinámico, puedes cambiarlo por tu lógica.
  };

  const handleDownloadClick = () => {
    return () => {
      window.open("https://catwatchful.pink/apk/getInstaller.php");
      ReactGA.event({ action: "nuevaDescarga", category: "eventoDescarga", value: 1 });
    };
  };

  return (
    <div className="blackGradient">
      <div id="download" className="container scrollMargin col-md-6 mt-4 pb-4">
        <SectionTitle name={t("download.title")} />

        <div className="py-1 mt-0 d-flex justify-content-start">
          <div className=" pt-1 d-flex bg-dark p-1  px-2 rounded" style={{ alignItems: "center" }}>
            <img src={process.env.PUBLIC_URL + "/images/minicat.png"} className="img-responsive" width="30px" />
            <div className="text-uppercase small ml-1">{t("download.version")} {getCurrentAppVersion()}</div>
          </div>
        </div>

        <div className="my-2 text-light p-1 px-2" style={{ fontSize: "0.8em", maxWidth: "700px", textJustify:"inter-word", textAlign:"justify" }}>
          {t("download.description")}
        </div>


        <div className="text-center mt-4">
          <div className="small">{t("download.qr")}</div>
          <img src={process.env.PUBLIC_URL + "/images/qr-code.png"} width={"150px"} alt="" />
        </div>

        <div className="text-center mt-4">
          <div className="small">{t("download.direct")}</div>

          <div className="d-flex justify-content-center mt-0">
            <div className="blob red">
              <button onClick={handleDownloadClick()} className="btn btn-danger text-light" style={{ width: "240px", height: "45px" }}>
                <i style={{ fontSize: "28px" }} className="fa fa-cloud-download mr-1" />
                {t("download.downloadButton")}
              </button>
            </div>
          </div>

        </div>


        <div className="d-flex justify-content-center ">
          <div className="mt-4" style={{ fontSize: "0.7em", width: "350px", paddingLeft: "20px" }}>
            {/* <div>Hemos diseñado Catwatchful para que funcione en todo tipo de dispositivos Android: con jailbreak, rooteados o nuevos.</div> */}

            <div className="mt-1">
              <div className="mb-1"><i className="fa fa-user-plus bg-dark p-1 mx-1 rounded text-center text-danger mr-2" style={{ width: "30px", height: "30px", alignContent: "center", fontSize: "1.4em" }} />
                {t("download.warning1")}
              </div>
              <div className="mb-1"><i className="fa-solid fa-mobile-screen bg-dark p-1 mx-1 rounded text-center text-danger mr-2" style={{ width: "30px", height: "30px", alignContent: "center", fontSize: "1.4em" }} />
                {t("download.warning2")}
              </div>
              <div className="mb-1"><i className="fa fa-unlock-alt bg-dark p-1 mx-1 rounded text-center text-danger mr-2" style={{ width: "30px", height: "30px", alignContent: "center", fontSize: "1.4em" }} />
                {t("download.warning3")}
              </div>
              <div className="mb-1"><i className="fa fa-hourglass-half bg-dark p-1 mx-1 rounded text-center text-danger mr-2" style={{ width: "30px", height: "30px", alignContent: "center", fontSize: "1.4em" }} />
                {t("download.warning4")}
              </div>
              <div className="mb-1"><i className="fa fa-warning bg-dark p-1 mx-1 rounded text-center text-danger mr-2" style={{ width: "30px", height: "30px", alignContent: "center", fontSize: "1.4em" }} />
                {t("download.readInstructions")}
              </div>
            </div>
          </div>


        </div>



      </div>
    </div>
  );
}